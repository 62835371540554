<template>
  <div class="relative" ref="listContainer">
    <div id="input-container" class="input-container relative h-[48px] border border-solid border-silver rounded">

      <!-- Input Search -->
      <input
        @focus="showList = true"
        @input="searchInList()"
        type="text"
        autocomplete="off"
        :id="inputId"
        :name="name"
        required
        :disabled="disabled"
        ref="searchInput"
        :placeholder="type === 'first_level' ? selectedOptions.map(item => localize ? $t(item[optionLabel]) : item[optionLabel]).join(', ') : fullLocationName"
        :value="type === 'first_level' ? showList ? (localize ? $t($refs.searchInput.value) : $refs.searchInput.value) : selectedOptions.map(item => localize ? $t(item[optionLabel]) : item[optionLabel]).join(', ') : showList ? (localize ? $t($refs.searchInput.value) : $refs.searchInput.value) : fullLocationName"
        :class="`${showList ? '' : 'pt-1'} h-full w-full pl-4 pr-[40px] text-[14px] leading-4 outline-none border-0 bg-transparent placeholder-industrial-revolution truncate`"
      >

      <!-- Placeholder Name || Label Name -->
      <label :for="inputId" :class="`absolute flex gap-[1px] bg-${labelBackground || 'layout'} cursor-text text-industrial-revolution`">
        {{ labelPlaceholder }}
        <span v-if="required" class="text-fluorescent-red text-lg leading-none">*</span>
      </label>

      <!-- Icon Select -->
      <button class="absolute right-4 top-1/2 shrink-0 border-0 bg-transparent select-none cursor-pointer flex" style="transform: translateY(-50%);">
        <shipblu-icon iconLibrary="feather" :iconName="showList ? 'ChevronUpIcon' : 'ChevronDownIcon'" :iconClass="`text-${disabled ? 'silver' : 'industrial-revolution'} w-[14px] h-[14px]`" />
      </button>
    </div>

    <!-- List Container -->
    <div v-if="showList" class="absolute z-[99993] top-[54px] left-0 w-full overflow-y-hidden bg-white border border-solid border-silver rounded-[5px]">
      <p v-if="type === 'first_level' && filteredList.length === 0 || type === 'high_level' && filteredZones.length === 0" class="text-center px-2 py-[8px]">{{ $t('Sorry, no matching options') }}</p>
      <div id="list" v-else class="overflow-y-auto py-[8px] max-h-[350px]">

        <!-- First Level Type -->
        <template v-if="type === 'first_level'">
          <div class="flex flex-col gap-[8px]">
            <div v-for="(option, index) in filteredList" :key="index">
              <p @click="selectItems(option)" :class="`leading-[24px] px-[12px] py-[3px] cursor-pointer ${selectedOptions.some(item => item.id === option.id) ? 'bg-primary text-white' : 'text-black-halo hover:bg-carte-blanche'}`">{{ localize ? $t(option[optionLabel]) : option[optionLabel] }}</p>
            </div>
          </div>
        </template>

        <!-- High Level Type -->
        <template v-if="type === 'high_level'">
          <div class="pt-[5px] flex flex-col gap-[8px]" @click="$refs.searchInput.focus()">
            <div v-for="region in filteredZones.list.filter(item => item.code !== 'NOGOV')" :key="region.id" class="flex flex-col gap-[8px]">

              <!-- Governorate Name -->
              <div class="governorate-name px-[12px] flex items-center gap-[6px] w-full">
                <p class="text-industrial-revolution text-[12px] whitespace-nowrap">{{ $t(region.name) }}</p>
                <hr class="h-[1px] bg-gainsboro w-full border-0">
              </div>

              <!-- Cities List -->
              <ul v-for="city in region.cities" :key="city.id">
                <li>
                  <div
                    class="text-black px-[12px] text-[14px] cursor-pointer flex justify-between" @click="toggleItem(city)">
                    {{ $t(city.name) }}
                    <shipblu-icon v-if="city.zones" iconLibrary="material" iconName="keyboard_arrow_up" :iconClass="`text-industrial-revolution text-[14px]`" :style="city === expandedItem? 'transform:rotate(0deg)' : 'transform:rotate(180deg)'"/>
                  </div>

                  <!-- Zones List -->
                  <ul v-if="city === expandedItem" class="mt-[4px] flex flex-col gap-[8px]">
                    <li
                      v-for="zone in city.zones"
                      :key="zone.id"
                      @click="selectItems(zone)"
                      :class="`cursor-pointer text-[14px] leading-[24px] py-[3px] pl-[25px] ${selectedOptions.includes(zone) ? 'bg-primary hover:bg-primary text-white' : 'text-stone-cold bg-white hover:bg-stoic-white' }`"
                      >
                      {{ $t(zone.name) }}
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import i18nData from '../../i18n/i18nData'

export default {
  name: 'shipblu-select',
  props: {
    optionLabel: {
      type: String,
      required: true
    },
    inputId: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'first_level'
    },
    labelPlaceholder: String,
    options: Array,
    labelBackground: String,
    inputValue: [String, Object],
    defaultValue: [String, Object],
    disabled: Boolean,
    required: Boolean,
    multiple: Boolean,
    localize: Boolean
  },
  data () {
    return {
      showList: false,
      selectedOptions: [],
      searchQuery: '',
      filteredList: [],
      expandedItem: null,
      fullLocationName: ''
    }
  },
  watch: {
    showList (val) {
      if (val) {
        this.$refs.searchInput.value = ''
        this.$refs.searchInput.focus()
        this.searchInList()
        this.smoothScrollToInput()  
      }
    }
  },
  computed: {
    filteredZones () {
      if (!this.searchQuery) {
        return {
          list: this.filteredList,
          length: this.filteredList.reduce((total, region) => total + (region.cities || []).reduce((cityTotal, city) => cityTotal + (city.zones || []).length, 0), 0)
        }
      }
      const query = this.searchQuery.toLowerCase()
      const filteredList = this.filteredList
        .map((region) => {
          const matchingCities = (region.cities || [])
            .map((city) => {
              const matchingZones = (city.zones || []).filter((zone) => zone.name.toLowerCase().includes(query))
              return matchingZones.length || city.name.toLowerCase().includes(query) ? { ...city, zones: matchingZones } : null
            }).filter(Boolean)
          return matchingCities.length || region.name.toLowerCase().includes(query) ? { ...region, cities: matchingCities } : null
        }).filter(Boolean)
      const totalCount = filteredList.reduce((total, region) => total + (region.cities || []).reduce((cityTotal, city) => cityTotal + (city.zones || []).length, 0), 0)
      return { list: filteredList, length: totalCount }
    }
  },
  methods: {
    selectItems (option) {
      if (!this.multiple) {
        this.selectedOptions = []
      }
      if (this.selectedOptions.some(item => item[this.optionLabel] === option[this.optionLabel])) {
        this.selectedOptions.splice(this.selectedOptions.indexOf(option), 1)
      } else {
        this.selectedOptions.push(option)
        if (option !== null && this.type === 'high_level') this.formatLocationLabel(option)
      }
      this.showList = false
      this.$emit('selectedOptions', this.selectedOptions)
    },
    searchInList () {
      const query = this.$refs.searchInput.value.toLowerCase()
      this.searchQuery = query
      if (this.type === 'first_level') this.filteredList = this.options.filter(item => (item && item[this.optionLabel].toLowerCase().includes(query)))
      // Emit @search event  
      this.$emit('input', this.searchQuery)
    },
    handleClickOutside (event) {
      // Check if the click happened outside the listContainer
      if (this.$refs.listContainer && !this.$refs.listContainer.contains(event.target)) {
        this.showList = false
        // Emit @search event  
        this.$emit('blur')
      } else if (!this.disabled && this.$refs.listContainer && this.$refs.listContainer.contains(event.target) && (event.target.parentElement.classList.contains('feather-icon') || event.target.parentElement.classList.contains('feather'))) {
        this.showList = !this.showList
        event.stopPropagation()
        event.preventDefault()
      } else {
        this.showList = !this.disabled
      }
    },
    toggleItem (item) {
      this.expandedItem = this.expandedItem === item ? null : item
    },
    smoothScrollToInput () {
      requestAnimationFrame(() => {
        const maxHeightList = 350
        const inputElement = document.querySelector(`input[id="${this.inputId}"]`)
        const parentElement = document.getElementById('scrollContainer')
        const listElement = document.getElementById('list')
        if (listElement) listElement.style.maxHeight = `${maxHeightList}px`
        if (inputElement && parentElement) {
          const inputBounds = inputElement.getBoundingClientRect()
          const parentBounds = parentElement.getBoundingClientRect()
          const inputOffset = inputBounds.top - parentBounds.top + parentElement.scrollTop
          const parentHeight = parentElement.clientHeight
          let scrollTo = parentElement.scrollTop
          if (Math.abs(parentHeight - inputOffset) < maxHeightList) {
            scrollTo = inputOffset - 50
          }
          parentElement.scrollTo({
            top: scrollTo,
            behavior: 'smooth'
          })
        }
      })
    },
    formatLocationLabel (option) {
      for (const governorate of this.filteredList) {
        if (governorate.code !== 'NOGOV') {
          for (const city of governorate.cities) {
            if (Array.isArray(city.zones)) {
              const zoneMatch = city.zones.find(zone => zone.id === option.id)
              if (zoneMatch) {
                this.fullLocationName = `${i18nData[this.$i18n.locale][governorate.name]} - ${i18nData[this.$i18n.locale][city.name]} - ${i18nData[this.$i18n.locale][zoneMatch.name]}` 
              }
            } 
          }
        }
      }
    }
  },
  mounted () {
    this.filteredList = this.options
    // default value in type first_level
    if (this.inputValue) this.selectItems(this.inputValue)
    // default value in type high_level
    if (this.defaultValue) this.selectItems(this.defaultValue.zone ? this.defaultValue.zone : null)
    this.expandedItem = this.defaultValue ? this.defaultValue.city : null
    // Add a global click event listener
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy () {
    // Clean up the event listener when the component is destroyed
    document.removeEventListener('click', this.handleClickOutside)
  }
}
</script>

<style lang="scss" scoped>
label {
  top: 50%; 
  left: 14px;
  color: #737373;
  transition: all .15s ease-in-out;
  transition-delay: 0.1s;
  transform: translateY(-50%);
}
input:focus,
input:valid,
input:disabled {
  & + label {
    top: -0.5px;
    left: 10px;
    font-size: 12px;
    padding: 0px 4px;
  }
}
input:disabled {
  & + label {
    color: #BFBFBF !important;
  }
}
.input-container:has(input:focus) {
  outline: 1px solid #1C5BFE;
  border: 1px solid #1C5BFE !important;
}
.input-container:has(input:disabled) {
  border: 1px solid #DCDCDC !important;
  pointer-events: none;
  input {
    color: #BFBFBF !important;
  }
}
</style>